import React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import { isFeatureEnabled } from "@reliance/feature-flags";
import userService from "../../services/userService";
import {
  getCookie,
  getAccountsDashboardUrl,
  DAARA_COOKIE_NAME,
} from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";
import NotAuthorizedComponent from "../NotAuthorizedComponent";
import { features } from "../../services/constants";

export function withUser(WrappedComponent) {
  const mapStateToProps = ({ session }) => ({
    sessionUser: session.user,
  });
  return connect(mapStateToProps)(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          isAuth: false,
          user: null,
          error: null,
        };
      }

      componentDidMount() {
        //check cookie
        //if cookie exists,
        //    check session user
        //        if session user exists
        //             check if cookie and session user accesstoken are the same
        //                  if tokens are the same
        //                      return child component with user prop attached
        //                  if token are not the same
        //                      re-auth with cookie token, and refresh app
        //       if session user doesnt exist
        //            auth with cookie token and create session user
        //if cookie doesnt exists
        //    logout (clear session user) and redirect to accounts login.
        let cookie = isFeatureEnabled(features.jwtToken)
          ? getCookie(DAARA_COOKIE_NAME)
          : getCookie();
        if (cookie) {
          sessionService
            .loadUser()
            .then((user) => {
              if (user.credentials.accessToken === cookie) {
                //set authenticated as true
                this.setUser(user);
              } else {
                this.authUser();
              }
            })
            .catch((e) => {
              this.authUser();
            });
        } else {
          this.logout();
        }
      }

      setUser = (user) => {
        this.setState({
          user,
          isAuth: true,
        });
      };

      setError = (error) => {
        this.setState({
          isAuth: false,
          error,
        });
      };

      logout = () => {
        window.location.href = `/logout?r=${encodeURI(window.location.href)}`;
      };

      authUser = () => {
        userService
          .auth()
          .then((user) => {
            sessionService
              .saveSession(user.credentials.accessToken)
              .then(() => {
                sessionService.saveUser(user).then(() => {
                  this.setUser(user);
                });
              });
          })
          .catch((e) => {
            this.setError({
              code: e.response.status,
              message: e.response.data.message,
            });
          });
      };

      goToUrl = (url) => {
        window.location.href = url;
      };

      handleSetUser = (user) => {
        this.setUser(user);
      };

      componentDidUpdate() {
        if (
          this.state.isAuth &&
          !_.isEmpty(this.props.sessionUser) &&
          JSON.stringify(this.props.sessionUser) !==
            JSON.stringify(this.state.user)
        ) {
          this.setUser(this.props.sessionUser);
        }
      }

      render() {
        if (this.state.error) {
          if (this.state.error.code === 401) {
            //unauthorized, ask them to login
            this.goToUrl(
              `${getAccountsDashboardUrl()}/login?r=${encodeURI(
                window.location.href
              )}`
            );
            return <LoadingScreen />;
          } else if (this.state.error.code === 403) {
            this.goToUrl(`${getAccountsDashboardUrl()}`);
            return <LoadingScreen />;
          } else {
            return (
              <NotAuthorizedComponent
                redirectUrl={getAccountsDashboardUrl() + "/welcome"}
                message={this.state.message}
              />
            );
          }
        } else {
          return this.state.isAuth ? (
            <WrappedComponent
              user={this.state.user}
              setUser={this.handleSetUser}
              {...this.props}
            />
          ) : (
            <LoadingScreen />
          );
        }
      }
    }
  );
}
