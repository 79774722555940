/**
 * Created by the.last.mayaki on 31/10/2017.
 */
import axios from "axios";
import daaraConfig from "./daara-config.json";
import { DAARA_ENROLLEE_BASE_URL, BASE_URL_RELIANCE } from "./utilityService";
import { sendHttpGetRequest } from "./axiosInterceptor";
import apiService, { ApiServiceException } from "./ApiService";

const BASE_URL = BASE_URL_RELIANCE;

class ProviderService {
  constructor() {
    this.ax = apiService;
    this.data = {};
  }

  async getProviders(accessToken, providerTypeId) {
    if (daaraConfig.features.provider_service.get_enrollee_providers) {
      const response = await sendHttpGetRequest(
        `${DAARA_ENROLLEE_BASE_URL}/enrollees/providers${
          providerTypeId ? "?type_id=" + providerTypeId : ""
        }`
      );
      return response.data;
    } else {
      try {
        const response_1 = await this.ax.get(
          `/enrollees/providers${
            providerTypeId ? "?type_id=" + providerTypeId : ""
          }`
        );
        return response_1.data;
      } catch (error) {
        throw new ApiServiceException(error);
      }
    }
  }
} // <-- Added closing brace here for the class

export default new ProviderService();
